// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__Ets2h {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 16px 18.8px 0px #DEC1B7;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
}
.style_container__Ets2h .style_titleFirst__n\\+\\+06 {
  color: #919CA4;
  text-align: center;
  font-family: "SF Pro Display";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.012px;
  margin: 10px;
}
.style_container__Ets2h .style_titleSecond__OgTiJ {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Nunito;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 34px;
  letter-spacing: 0.4px;
}
.style_container__Ets2h .style_titleSecond__OgTiJ span {
  color: #8FDC13;
}`, "",{"version":3,"sources":["webpack://./src/pages/personal-plan/layouts/goalGraph/style.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uCAAA;EACA,WAAA;EACA,mBAAA;EACA,cAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,YAAA;AACJ;AAEE;EACE,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAAJ;AAEI;EACE,cAAA;AAAN","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  border-radius: 16px;\n  background: #FFF;\n  box-shadow: 0px 16px 18.8px 0px #DEC1B7;\n  width: 100%;\n  height: fit-content;\n  flex-shrink: 0;\n  \n  .titleFirst {\n    color: #919CA4;\n    text-align: center;\n    font-family: \"SF Pro Display\";\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 22px;\n    letter-spacing: -0.012px;\n    margin: 10px;\n  }\n\n  .titleSecond {\n    color: #000;\n    text-align: center;\n    font-feature-settings: 'liga' off, 'clig' off;\n    font-family: Nunito;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 800;\n    line-height: 34px;\n    letter-spacing: 0.4px;\n\n    span {\n      color: #8FDC13;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__Ets2h`,
	"titleFirst": `style_titleFirst__n++06`,
	"titleSecond": `style_titleSecond__OgTiJ`
};
export default ___CSS_LOADER_EXPORT___;
