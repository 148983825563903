import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
    minimumFetchIntervalMillis: 0,
}

remoteConfig.defaultConfig = {
    three_day_plan: {
        "name": "3 Day Trial",
        "price": "$1",
        "discount": "$5.49",
        "text": "Then 1-Month Plan $1.33/day",
        "right_price": "$1",
        "right_text": "3 Day Trial"
    },
    one_week_plan: {
        "name": "1 Week Trial",
        "price": "$5.49",
        "discount": "$5.49",
        "text": "Then it's $9.99 a week.",
        "right_price": "$5.49",
        "right_text": "1 Week Trial"
    },
    four_week_plan: {
        "name": "4 Week Trial",
        "price": "$5.49",
        "discount": "$5.49",
        "text": "Then it's $9.99 a week.",
        "right_price": "$5.49",
        "right_text": "4 Week Trial"
    }
};

export { app, analytics, remoteConfig };