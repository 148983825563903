// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statement {
  padding-bottom: 40px;
}
.statement .message {
  border-radius: 12px 12px 12px 0px;
  background: #EAECFF;
  padding: 12px 12px;
  margin-top: 25px;
  font-size: 15px;
  color: #585F64;
  font-weight: 500;
  font-family: "SF Pro Display", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/pages/statement/style.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,iCAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,yCAAA;AACJ","sourcesContent":[".statement {\n  padding-bottom: 40px;\n\n  .message {\n    border-radius: 12px 12px 12px 0px;\n    background: #EAECFF;\n    padding: 12px 12px;\n    margin-top: 25px;\n    font-size: 15px;\n    color: #585F64;\n    font-weight: 500;\n    font-family: 'SF Pro Display', sans-serif;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
