import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {MultiCheck} from "../../components/ui/multi-check/multi-check";
import {MultiOption} from "../../components/ui/multi-check/option";
function Page() {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const prepareMeals = useStore((state) => state.prepareMeals)
    const setPrepareMeals = useStore((state) => state.setPrepareMeals)

    return (
        <div className={'page prepare-meals'}>
            <div className={'back'} onClick={() => navigate('/meals-count')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How do you typically prepare your meals?</div>
            </div>

            <div className={'controls'}>
                <MultiCheck defaultValue={prepareMeals} onChange={(value) => {
                    if (value.length > 0) {
                        setNext(true)
                    } else {
                        setNext(false)
                    }
                    setPrepareMeals(value)
                }}>
                    <MultiOption value={'home'}>🎛   I mostly cook at home</MultiOption>
                    <MultiOption value={'restaurant'}>🥄   I’d rather go to a restaurant</MultiOption>
                    <MultiOption value={'takeaway'}>🍔   I’m a big fun of takeaway food</MultiOption>
                    <MultiOption value={'everything'}>🌞   I do a little bit of everything</MultiOption>
                </MultiCheck>
                <div className={'overlay'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    next && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/daily-drink')}/>
                    )
                }
            </div>
        </div>
    );
}
export default Page;

