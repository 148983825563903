import React from 'react';
import styles from './styles/style.module.scss'
import Plans from './layout/plans'
import Button from '../../../../components/ui/button'
import Separator from './layout/separator'
import firstImg from './styles/first.png'
import tashaImg from './styles/tasha.png'
import sophiaImg from './styles/sophia.png'
import isabellaImg from './styles/isabella.png'
import { ReactComponent as StarSvg } from './styles/star.svg'
import { ReactComponent as HandSvg } from './styles/hand.svg'

const services = [
    "Personalized meal plan tailored to you",
    "Meal plan adapts to your needs in real time",
    "Easy food logging by taking a photo",
    "Analysis of your food logs with recommendations to help you reach your goal",
    "Assistance with choosing meals at restaurants",
    "Suggestions on what to cook with the ingredients you havev",
];

const customers = [
    {
        img: tashaImg,
        name: "Tasha",
        descriptions: "I’ve been trying to lose weight for years, and this app finally helped me reach my goal. The personalized meal plan and food suggestions made the process simple and effective!",
        weightLeft: 75,
        weightRight: 60
    },
    {
        img: sophiaImg,
        name: "Sophia",
        descriptions: "Amazing experience! I lost 10 kg in just a couple of months thanks to the easy food logging and recommendations on what to cook. The app really adapted to my needs.",
        weightLeft: 80,
        weightRight: 60
    },
    {
        img: isabellaImg,
        name: "Isabella",
        descriptions: "I love how the app analyzes my photos and gives tips to improve my diet. Losing weight has become so much easier, and I don’t have to worry about counting calories—it’s all done for me!",
        weightLeft: 90,
        weightRight: 55
    }
]
const PersonalPlan = () => {
    return (
        <div className={styles.container}>
           <h2 className={styles.chooseTitle}>Choose your plan</h2>
           <Plans/>
           <Button className={styles.startTrialBtn} title='Start Trial and Cantinue'/>
           <span className={styles.privacy}>By continuing I accept <a>privacy policy</a>, <a>terms of use</a>, billing terms an money-back policy</span>
            <Separator/>
           <span className={styles.privacy}>1-Month plan expires November 5, 2024. Today you will be charged $1 for 3-days trial, then $39.99 after trial per month. Cancel anytime and you won't be charged the next period payment. Learn more about cancellation and refund policy in Money-Back Guarantee Policy. To cancel, simply let us know: stylix.app@support-team.app. Subscription Terms</span>

            <h2 className={styles.eatTitle}>Eat what you love & <span>lose weight</span></h2>
            <img className={styles.firstImg} src={firstImg}/>
            <Button className={styles.startTrialBtn} title='Get my plan'/>
            <h2 className={styles.eatTitle}>What you<span> will get:</span></h2>
            
            <div className={styles.services}>
                {services.map( service => (
                        <div className={styles.service}>
                            <img src="/assets/images/active-check.svg" alt="check"/><span>{service}</span>
                        </div>
                ))}
            </div>
            <Button className={styles.startTrialBtn} title='Get my plan'/>
            
            <div className={styles.customers}>
                <h2 className={styles.customerTitle}>Customers reviews</h2>
                {customers.map(({img, name, descriptions, weightLeft, weightRight}) => (
                    <div className={styles.customer}>
                    <div className={styles.customerImgContainer}>
                        <img className={styles.customerImg} src={img}/>
                        <span className={styles.weightLeft}>{weightLeft} kg</span>
                        <span className={styles.weightRight}>{weightRight} kg</span>
                    </div>
                        
                        <div className={styles.customerTop}>
                            <span className={styles.customerName}>{name}</span>
                            <span className={styles.customerRating}>{[...Array(5).keys()].map(() => (<StarSvg/>))}</span>
                        </div>
                        <div className={styles.customerBody}>{descriptions}</div>
                    </div>
                ))}
                <div className={styles.moneyBack}>
                    <HandSvg/>
                    <h2>100% Money-back guarantee</h2>
                    <span className={styles.firsBody}>At EatEasy, our mission is to deliver practical and insightful style guidance for our users, with their satisfaction as our top priority.Therefore, we carefully designed our product and strongly believe in its quality. If you don't notice any progress after using the app for at least a week, we are ready to make a complete refund within 21 days..</span>
                    <span className={styles.secondBody}> Find more about the applicable limitations in our money-back policy.</span>
                </div>
            </div>
        </div>
    );
}
export default PersonalPlan;