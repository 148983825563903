import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const yourLifestyle = useStore((state) => state.yourLifestyle)
    const setYourLifestyle = useStore((state) => state.setYourLifestyle)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/work-schedule')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your lifestyle like?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={yourLifestyle} onChange={(value) => {
                    setYourLifestyle(value)
                    navigate('/go-for-walks')
                }}>
                    <Option value={'I spend my days sitting'}>💻   I spend my days sitting</Option>
                    <Option value={'I try to move when i can'}>🏌️‍♂️   I try to move when i can</Option>
                    <Option value={'Im on my feet all day long'}>👟   I’m on my feet all day long</Option>
                    <Option value={'I spend most of my day at home'}>🏘   I spend most of my day at home</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

