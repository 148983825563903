import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const changeMotivation = useStore((state) => state.changeMotivation)
    const setChangeMotivation = useStore((state) => state.setChangeMotivation)

    return (
        <div className={'page change-motivation'}>
            <div className={'back'} onClick={() => navigate('/improve-your-sleep')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How does your motivation change over time?</div>
                <div className={'description'}>
                    It helps us customize your program
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={changeMotivation} onChange={(value) => {
                    setChangeMotivation(value)
                    navigate('/statement')
                }}>
                    <Option value={'It stays consistent'}>It stays consistent</Option>
                    <Option value={'It ebbs and flow'}>It ebbs and flow</Option>
                    <Option value={'Im still finding it'}>Im still finding it</Option>
                </Check>
            </div>
        </div>
    );
}
export default Page;

