// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-graph {
  padding: 0 15px;
}
.wrapper-graph .graph {
  margin: 0 auto;
  background-color: #fff;
  padding: 45px 25px;
  border-radius: 16px;
  max-width: 450px;
  position: relative;
}
.wrapper-graph .graph img {
  width: 100%;
}
.wrapper-graph .graph span {
  font-family: "SF Pro Rounded", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.wrapper-graph .graph span.weight {
  position: absolute;
  left: 20px;
  top: 25px;
}
.wrapper-graph .graph span.goal-weight {
  position: absolute;
  right: 20px;
  top: 45%;
}
@media (max-width: 370px) {
  .wrapper-graph .graph span.goal-weight {
    top: 40%;
  }
}
.wrapper-graph .graph span.now {
  position: absolute;
  left: 20px;
  bottom: 25px;
  color: #919CA4;
}
.wrapper-graph .graph span.goal-date {
  position: absolute;
  right: 20px;
  color: #919CA4;
  bottom: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/graph/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AACI;EACE,WAAA;AACN;AAEI;EACE,yCAAA;EACA,eAAA;EACA,gBAAA;AAAN;AAEM;EACE,kBAAA;EACA,UAAA;EACA,SAAA;AAAR;AAGM;EACE,kBAAA;EACA,WAAA;EACA,QAAA;AADR;AAGQ;EALF;IAMI,QAAA;EAAR;AACF;AAGM;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,cAAA;AADR;AAIM;EACE,kBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;AAFR","sourcesContent":[".wrapper-graph {\n  padding: 0 15px;\n\n  .graph {\n    margin: 0 auto;\n    background-color: #fff;\n    padding: 45px 25px;\n    border-radius: 16px;\n    max-width: 450px;\n    position: relative;\n\n    img {\n      width: 100%;\n    }\n\n    span {\n      font-family: \"SF Pro Rounded\", sans-serif;\n      font-size: 16px;\n      font-weight: 600;\n\n      &.weight {\n        position: absolute;\n        left: 20px;\n        top: 25px;\n      }\n\n      &.goal-weight {\n        position: absolute;\n        right: 20px;\n        top: 45%;\n\n        @media (max-width: 370px) {\n          top: 40%;\n        }\n      }\n\n      &.now {\n        position: absolute;\n        left: 20px;\n        bottom: 25px;\n        color: #919CA4;\n      }\n\n      &.goal-date {\n        position: absolute;\n        right: 20px;\n        color: #919CA4;\n        bottom: 25px;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
