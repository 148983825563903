// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout {
  background: #F9F9F2;
  max-width: 700px;
  width: 100%;
  height: 100vh;
  height: 100svh;
  max-height: 100vh;
  max-height: 100svh;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/layouts/main/styles.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AACF","sourcesContent":[".layout {\n  background: #F9F9F2;\n  max-width: 700px;\n  width: 100%;\n  height: 100vh;\n  height: 100svh;\n  max-height: 100vh;\n  max-height: 100svh;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
