import React, {Fragment, } from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useStore} from "../../store/store";

gsap.registerPlugin(ScrollTrigger);

function Page() {
    const navigate = useNavigate();
    const height = useStore((state) => state.height)
    const setHeight = useStore((state) => state.setHeight)

    const Next = () => {
        if (height.type === 'sm') {
            if (height.value) {
                return (
                    <div className={'next-wrapper'}>
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/weight')}/>
                    </div>
                )
            }
        } else if (height.type === 'inch') {
            if (height.ft && height.in) {
                return (
                    <div className={'next-wrapper'}>
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/weight')}/>
                    </div>
                )
            }
        }
    }

    return (
        <div className={'page height'}>
            <div className={'back'} onClick={() => navigate('/name')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your height?</div>
                <div className={'description'}>
                    This will help us understand your calorie needs
                </div>
            </div>
            
            <div className={'controls controlsHeight'}>
                <div className={'tabs'}>
                    <span className={cn({'active': height.type === 'sm'})} onClick={() => {
                        setHeight({...height, type: 'sm'})
                    }}>SM</span>
                    <span className={cn({'active': height.type === 'inch'})} onClick={() => {
                        setHeight({...height, type: 'inch'})
                    }}>inch</span>
                </div>

                {
                    height.type === 'sm' && (
                        <Fragment>
                            <input style={{marginTop: 40}} type="number" autoFocus={true} value={height.value} placeholder={'Input height...'} onChange={(event) => setHeight({...height, value: event.target.value})}/>
                            <div className={'line'}></div>
                        </Fragment>

                    )
                }

                {
                    height.type === 'inch' && (
                        <div className={'wrapper-inputs'}>
                            <div>
                                <div className={'item'}>
                                    <input type="number" autoFocus={true} value={height.ft} onChange={(event) => setHeight({...height, ft: event.target.value})}/>
                                    <span>ft</span>
                                </div>
                                <div className={'line'}></div>
                            </div>
                            <div>
                                <div className={'item'}>
                                    <input type="number" value={height.in} onChange={(event) => setHeight({...height, in: event.target.value})}/>
                                    <span>in</span>
                                </div>
                                <div className={'line'}></div>
                            </div>
                        </div>

                    )
                }
            </div>
            <Next />
        </div>
    );
}

export default Page;

