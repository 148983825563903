import React, {useEffect, useState} from 'react';
import styles from './style.module.scss'
import {remoteConfig} from "../../../../../../firebase/firebase";
import { getAll, fetchConfig } from 'firebase/remote-config';

const Plans = () => {
    const [activePlan, setActivePlan] = useState(0);
    const [plans, setPlans] = useState(remoteConfig.defaultConfig);

    useEffect(() => {
        if (remoteConfig) {
            fetchConfig(remoteConfig)
                .then(() => {
                    const values = getAll(remoteConfig);

                    setPlans({
                        three_day_plan: JSON.parse(values.three_day_plan._value),
                        one_week_plan: JSON.parse(values.one_week_plan._value),
                        four_week_plan: JSON.parse(values.four_week_plan._value),
                    });
                })
                .catch((error) => {
                    console.error("Error Remote Config:", error);
                });
        }
    }, []);

    useEffect(() => {
        console.log(plans)
    }, [plans])

    return (
        <div className={styles.container}>
            <div
                onClick={() => setActivePlan(0)}
                className={`${styles.planCard} ${activePlan === 0 && styles.active}`}
            >
                <div className={styles.left}>
                    <div className={styles.planTrial}>{plans.three_day_plan.name}</div>
                    <div className={styles.planPrice}>{plans.three_day_plan.price}<span className={styles.oldPrice}> {plans.three_day_plan.discount}</span></div>
                    <div className={styles.planDescription}>{plans.three_day_plan.text}</div>
                </div>

                <div className={styles.right}>
                    <div className={styles.planPrice}>{plans.three_day_plan.right_price}</div>
                    <div className={styles.planTrial}>{plans.three_day_plan.right_text}</div>
                </div>

            </div>
            <div
                onClick={() => setActivePlan(1)}
                className={`${styles.planCard} ${activePlan === 1 && styles.active}`}
            >
                <div className={styles.extraTitle}>Most popular</div>

                <div className={styles.left}>
                    <div className={styles.planTrial}>{plans.one_week_plan.name}</div>
                    <div className={styles.planPrice}>{plans.one_week_plan.price}<span className={styles.oldPrice}> {plans.one_week_plan.discount}</span></div>
                    <div className={styles.planDescription}>{plans.one_week_plan.text}</div>
                </div>

                <div className={styles.right}>
                    <div className={styles.planPrice}>{plans.one_week_plan.right_price}</div>
                    <div className={styles.planTrial}>{plans.one_week_plan.right_text}</div>
                </div>
            </div>
            <div
                onClick={() => setActivePlan(2)}
                className={`${styles.planCard} ${activePlan === 2 && styles.active}`}
            >
                <div className={styles.extraTitle}>Most popular</div>

                <div className={styles.left}>
                    <div className={styles.planTrial}>{plans.four_week_plan.name}</div>
                    <div className={styles.planPrice}>{plans.four_week_plan.price}<span className={styles.oldPrice}> {plans.four_week_plan.discount}</span></div>
                    <div className={styles.planDescription}>{plans.four_week_plan.text}</div>
                </div>

                <div className={styles.right}>
                    <div className={styles.planPrice}>{plans.four_week_plan.right_price}</div>
                    <div className={styles.planTrial}>{plans.four_week_plan.right_text}</div>
                </div>
            </div>
        </div>
    );
}
export default Plans;