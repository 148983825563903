import React from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";

function Page() {
    const navigate = useNavigate();
    const email = useStore((state) => state.email)
    const setEmail = useStore((state) => state.setEmail)

    return (
        <div className={'page'}>
            <div className={'back'} onClick={() => navigate('/goal')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>We predict you will reach your goal</div>
                <div className={'description'}>
                    Enter your email to continue building your plan
                </div>
            </div>
            
            <div className={'controls controlsName'}>
                <input type="email" value={email} placeholder={'Input email...'} onChange={(event) => setEmail(event.target.value)}/>
                <div className={'line'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    email && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/meals-count')}/>
                    )
                }
            </div>
        </div>
    );
}

export default Page;

