// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.born {
  padding-bottom: 40px;
}
.born .controls {
  width: 100%;
  margin: 0 auto;
}
.born .controls input {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 16px;
  font-family: "SF Pro Rounded", sans-serif;
  max-width: 210px;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
}
.born .controls .line {
  width: 100%;
  background: #B4B4B4;
  height: 1px;
}
.born .picker-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transform-origin: bottom;
  background-color: #fff;
}
.born .picker-wrapper .data-picker div:not(.text-neutral, .text-neutral-bold, .item, .col):last-child {
  background: #f4f4f4;
  border-radius: 16px;
  z-index: -1;
  left: 21px !important;
  right: 0 !important;
  width: 90% !important;
}
.born .picker-wrapper .data-picker div:not(.text-neutral, .text-neutral-bold, .item, .col):last-child div {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/born/style.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAAE;EACE,WAAA;EACA,cAAA;AAEJ;AADI;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yCAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;AAGN;AAAI;EACE,WAAA;EACA,mBAAA;EACA,WAAA;AAEN;AAWE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,wBAAA;EACA,sBAAA;AATJ;AAYM;EACE,mBAAA;EACA,mBAAA;EACA,WAAA;EACA,qBAAA;EACA,mBAAA;EACA,qBAAA;AAVR;AAWQ;EACE,aAAA;AATV","sourcesContent":[".born {\n  padding-bottom: 40px;\n  .controls {\n    width: 100%;\n    margin: 0 auto;\n    input {\n      text-align: center;\n      font-size: 24px;\n      font-weight: 600;\n      line-height: 16px;\n      font-family: \"SF Pro Rounded\", sans-serif;\n      max-width: 210px;\n      width: 100%;\n      background: transparent;\n      border: none;\n      outline: none;\n    }\n\n    .line {\n      width: 100%;\n      background: #B4B4B4;\n      height: 1px;\n    }\n  }\n\n  //.next-wrapper {\n  //  position: absolute;\n  //  top: -80px;\n  //  left: 0;\n  //  right: 0;\n  //  z-index: 1;\n  //  height: 69px;\n  //}\n\n  .picker-wrapper {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 2;\n    transform-origin: bottom;\n    background-color: #fff;\n\n    .data-picker {\n      div:not(.text-neutral, .text-neutral-bold, .item, .col):last-child{\n        background: #f4f4f4;\n        border-radius: 16px;\n        z-index: -1;\n        left: 21px !important;\n        right: 0 !important;\n        width: 90% !important;\n        div {\n          display: none;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
