import React, {useContext} from 'react'
import './styles.scss';
import {MultiCheckContext} from "./context";
import cn from "classnames";
export const MultiOption = ({children, value}) => {
    const {handleChange, currentValue} = useContext(MultiCheckContext)

    return (
        <div className={cn('option', {
            'active': currentValue.findIndex((v) => v === value) !== -1
        })} onClick={() => {
            handleChange(value);
        }}>
            <span>{children}</span>
            {
                currentValue.findIndex((v) => v === value) !== -1 ? (
                    <img src="/assets/images/active-check.svg" alt="check"/>
                ) : (
                    <img src="/assets/images/check.svg" alt="check"/>
                )
            }
        </div>
    )
}