import React from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";

function Page() {
    const navigate = useNavigate();
    const name = useStore((state) => state.name)
    const setName = useStore((state) => state.setName)

    return (
        <div className={'page name'}>
            <div className={'back'} onClick={() => navigate('/born')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your preferred first name?</div>
                <div className={'description'}>
                    This will help us helps us personalize your experience
                </div>
            </div>
            
            <div className={'controls'}>
                <input type="text" value={name} placeholder={'Input name...'} onChange={(event) => setName(event.target.value)}/>
                <div className={'line'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    name && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/height')}/>
                    )
                }
            </div>
        </div>
    );
}

export default Page;

