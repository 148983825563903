import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {format} from "date-fns";
import Graph from '../../components/ui/graph'

function Page() {
    const navigate = useNavigate();
    const idealWeight = useStore((state) => state.idealWeight)
    const when = useStore((state) => state.when)
    const weight = useStore((state) => state.weight)

    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <div className={'page goal'}>
            <div className={'back'} onClick={() => navigate('/when')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>
                    <span style={{color: '#8FDC13'}}>{idealWeight.value} {idealWeight.type}</span>
                    {' '} by {format(new Date(when.year + '-' + when.month+'-'+ when.day +'T00:00:00'), 'MMMM yyyy')}
                </div>
                <div className={'description'}>
                    You're set for success!  We predict you'll reach your weight loss goal
                </div>
            </div>

            <Graph
                when={when}
                weight={weight}
                idealWeight={idealWeight}
                setImageLoaded={setImageLoaded}
                imageLoaded={imageLoaded}
            />


            <div className={'next-wrapper'}>
                <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/email')}/>
            </div>
        </div>
    );
}

export default Page;

