// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  margin: 0 auto;
  background: #F9F9F2;
  width: 100%;
  color: #89939B;
  text-align: center;
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  opacity: 0.7;
}

.contact-us {
  line-height: 19.5px;
  letter-spacing: 0.24px;
  width: 100%;
  padding-bottom: 14px;
  border-bottom: 1px solid #E1E1E1;
  text-decoration-line: underline;
  font-style: normal;
}

.address {
  width: 300px;
  line-height: 20px;
  letter-spacing: 0.24px;
  margin: 14px 0;
}

.links {
  padding: 0 12px;
  line-height: 41px;
  width: 100%;
  text-decoration-line: underline;
}

.links_top {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
@media (max-width: 414px) {
  .links_top {
    gap: 15px;
  }
}
@media (max-width: 375px) {
  .links_top {
    gap: 10px;
    padding: 0 10px;
  }
}

.links a {
  text-decoration: none;
  color: #89939B;
}

.links a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/layouts/footer/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AACF;;AAEA;EACE,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,oBAAA;EACA,gCAAA;EACA,+BAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,WAAA;EACA,+BAAA;AACF;;AAEA;EACE,aAAA;EACA,eAAA;EACA,uBAAA;EACA,SAAA;AACF;AACE;EANF;IAOI,SAAA;EAEF;AACF;AAAE;EAVF;IAWI,SAAA;IACA,eAAA;EAGF;AACF;;AAAA;EACE,qBAAA;EACA,cAAA;AAGF;;AAAA;EACE,0BAAA;AAGF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 24px 16px;\n  margin: 0 auto; \n  background: #F9F9F2;\n  width: 100%;\n  color: #89939B;\n  text-align: center;\n  font-family: Inter;\n  font-weight: 400;\n  font-size: 15px;\n  opacity: 0.7;\n}\n\n.contact-us {\n  line-height: 19.5px; \n  letter-spacing: 0.24px;\n  width: 100%;\n  padding-bottom: 14px;\n  border-bottom: 1px solid #E1E1E1;\n  text-decoration-line: underline;\n  font-style: normal;\n}\n\n.address {\n  width: 300px;\n  line-height: 20px;\n  letter-spacing: 0.24px;\n  margin: 14px 0;\n}\n\n.links {\n  padding: 0 12px;\n  line-height: 41px;\n  width: 100%;\n  text-decoration-line: underline;\n}\n\n.links_top {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  gap: 30px;\n\n  @media (max-width: 414px) {\n    gap: 15px;\n  }\n\n  @media (max-width: 375px) {\n    gap: 10px;\n    padding: 0 10px;\n  }\n}\n\n.links a {\n  text-decoration: none;\n  color: #89939B;\n}\n\n.links a:hover {\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
