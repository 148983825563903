import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const loosGoal = useStore((state) => state.loosGoal)
    const setLoosGoal = useStore((state) => state.setLoosGoal)

    return (
        <div className={'page loos-goal'}>
            <div className={'back'} onClick={() => navigate('/weight')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your weight loss goal?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={loosGoal} onChange={(value) => {
                    setLoosGoal(value)
                    navigate('/ideal-weight')
                }}>
                    <Option value={'1-10'}>Lose 1-10 kg for good</Option>
                    <Option value={'11-20'}>Lose 11-20 kg for good</Option>
                    <Option value={'20'}>Lose over 20 kg for good</Option>
                    <Option value={'20'}>Maintain weight and get fit</Option>
                </Check>
            </div>
        </div>
    );
}
export default Page;

