// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #000;
  color: #FFF;
  font-family: Nunito;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/button/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;AACF","sourcesContent":[".primary {\n  display: flex;\n  height: 50px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  border-radius: 8px;\n  background: #000;\n  color: #FFF;\n  font-family: Nunito;\n  font-size: 17px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  cursor: pointer;\n  border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
