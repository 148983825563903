// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prepare-meals {
  padding-bottom: 180px;
}
.prepare-meals .controls {
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.prepare-meals .controls::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/prepare-meals/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,kBAAA;EAIA,wBAAA;EACA,qBAAA;AADJ;AAHI;EACE,aAAA;AAKN","sourcesContent":[".prepare-meals {\n  padding-bottom: 180px;\n  .controls {\n    position: relative;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n    -ms-overflow-style: none;\n    scrollbar-width: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
