import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const goForWalks = useStore((state) => state.goForWalks)
    const setGoForWalks = useStore((state) => state.setGoForWalks)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/your-lifestyle')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How often do you go for walks?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={goForWalks} onChange={(value) => {
                    setGoForWalks(value)
                    navigate('/work-out')
                }}>
                    <Option value={'Almost every day'}>💪   Almost every day</Option>
                    <Option value={'3-4 times a week'}>😎   3-4 times a week</Option>
                    <Option value={'1-2 times a week'}>🙂   1-2 times a week</Option>
                    <Option value={'Maybe once a month'}>😌   Maybe once a month</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

