import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const gender = useStore((state) => state.gender)
    const setGender = useStore((state) => state.setGender)

    return (
        <div className={'page gender'}>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your gender?</div>
                <div className={'description'}>
                    We’ll customize your plan based on your body's unique needs
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={gender} onChange={(value) => {
                    setGender(value)
                    navigate('/born')
                }}>
                    <Option value={'Male'}>Male</Option>
                    <Option value={'Female'}>Female</Option>
                    <Option value={'Non-Binary'}>Non-Binary</Option>
                    <Option value={'Other'}>Other</Option>
                </Check>
            </div>
        </div>
    );
}
export default Page;

