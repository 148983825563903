import React, { useState, useEffect } from 'react';
import styles from './styles/style.module.scss';
import GoalGraph from './layouts/goalGraph';
import PersonalPlan from './layouts/personalPlan';
import Footer from '../../layouts/footer';
import Button from '../../components/ui/button';

const PersnalPlan = () => {
    const [timeLeft, setTimeLeft] = useState(595);
    const [isTimerVisible, setIsTimerVisible] = useState(true);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setIsTimerVisible(false);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <>
            <div className={styles.container}>
                <div className={styles.gradientBg}></div>
                <h1 className={styles.title}>Your <span>personalized plan</span> is ready!</h1>
                <GoalGraph />
                <PersonalPlan />
            </div>
            <Footer />

            {isTimerVisible && (
                <div className={styles.timer}>
                    <span>{formatTime(timeLeft)}</span>
                    <Button title='Get my plan' />
                </div>
            )}
        </>
    );
};

export default PersnalPlan;