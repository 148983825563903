import React, {useContext} from 'react'
import './styles.scss';
import {CheckContext} from "./context";
import cn from "classnames";
export const Option = ({children, value}) => {
    const {handleChange, currentValue} = useContext(CheckContext)
    return (
        <div className={cn('option', {
            'active': currentValue === value
        })} onClick={() => {
            handleChange(value, currentValue);
        }}>
            <span>{children}</span>
            {
                currentValue === value ? (
                    <img src="/assets/images/active-check.svg" alt="check"/>
                ) : (
                    <img src="/assets/images/check.svg" alt="check"/>
                )
            }
        </div>
    )
}