import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const sleepPerNight = useStore((state) => state.sleepPerNight)
    const setSleepPerNight = useStore((state) => state.setSleepPerNight)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/work-out')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How many hours do you sleep per night?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={sleepPerNight} onChange={(value) => {
                    setSleepPerNight(value)
                    navigate('/improve-your-sleep')
                }}>
                    <Option value={'Less than 5 hours'}>🤪  Less than 5 hours</Option>
                    <Option value={'5-6 hours'}>🖐   5-6 hours</Option>
                    <Option value={'7-8 hours'}>👌   7-8 hours</Option>
                    <Option value={'More than 8 hours'}>😥   More than 8 hours</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

