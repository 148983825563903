import React from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useStore} from "../../store/store";

// const monthsArray = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December',
// ];

// function getDayArray(year, month) {
//     const dayCount = new Date(year, month, 0).getDate()
//     return Array.from({ length: dayCount }, (_, i) => String(i + 1).padStart(2, '0'))
// }

function Page() {
    const navigate = useNavigate();

    const born = useStore((state) => state.born)
    const setBorn = useStore((state) => state.setBorn)

    // const [isOpen, setIsOpen] = useState(false);
    // const [formattedDate, setFormattedDate] = useState();


    // const [pickerValue, setPickerValue] = useState({
    //     year: '1994',
    //     month: '05',
    //     day: '12',
    // })
    //
    // const handlePickerChange = useCallback((newValue, key) => {
    //     const { year, month } = newValue
    //     const newDayArray = getDayArray(Number(year), Number(month))
    //     const newDay = newDayArray.includes(newValue.day) ? newValue.day : newDayArray[newDayArray.length - 1]
    //     setPickerValue({ ...newValue, day: newDay })
    //
    //     setFormattedDate(
    //         format(new Date(newValue.year + '-' + newValue.month+'-'+ newDay +'T00:00:00'), 'dd MMM yyyy')
    //     )
    // }, [])

    // const pickerAnimate = {
    //     enter: {
    //         scaleY: 1,
    //         transition: {
    //             duration: 0.2,
    //             ease: 'easeIn'
    //         },
    //         display: "block"
    //     },
    //     exit: {
    //         scaleY: 0,
    //         transition: {
    //             duration: 0.2,
    //             ease: 'easeOut'
    //         },
    //         transitionEnd: {
    //             display: "none"
    //         }
    //     }
    // };

    return (
        <div className={'page born'}>
            <div className={'back'} onClick={() => navigate('/')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your age?</div>
                <div className={'description'}>
                    We use your age to align the nutrition plan with your metabolic needs
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={born} onChange={(value) => {
                    setBorn(value)
                    navigate('/name')
                }}>
                    <Option value={'18-19 years'}>18-19 years</Option>
                    <Option value={'30-39 years'}>30-39 years</Option>
                    <Option value={'40-49 years'}>40-49 years</Option>
                    <Option value={'50-59 years'}>50-59 years</Option>
                    <Option value={'60-80 years'}>60-80 years</Option>
                </Check>
            </div>



            {/*<div className={'picker-wrapper'}>*/}
            {/*    <div className={'next-wrapper'}>*/}
            {/*        {*/}
            {/*            formattedDate && (*/}
            {/*                <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/name')}/>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    <Picker*/}
            {/*        value={pickerValue}*/}
            {/*        onChange={handlePickerChange}*/}
            {/*        wheelMode="natural"*/}
            {/*        height={150}*/}
            {/*        className={'data-picker'}*/}
            {/*    >*/}
            {/*        <Picker.Column name="month" className={'col'}>*/}
            {/*            { Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')).map((month, index) => (*/}
            {/*                <Picker.Item key={month} value={month} className={'item'}>*/}
            {/*                    {({ selected }) => (*/}
            {/*                        <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{monthsArray[index]}</div>*/}
            {/*                    )}*/}
            {/*                </Picker.Item>*/}
            {/*            ))}*/}
            {/*        </Picker.Column>*/}
            {/*        <Picker.Column name="day" className={'col'}>*/}
            {/*            { getDayArray(Number(pickerValue.year), Number(pickerValue.month)).map((day) => (*/}
            {/*                <Picker.Item key={day} value={day} className={'item'}>*/}
            {/*                    {({ selected }) => (*/}
            {/*                        <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{day}</div>*/}
            {/*                    )}*/}
            {/*                </Picker.Item>*/}
            {/*            ))}*/}
            {/*        </Picker.Column>*/}
            {/*        <Picker.Column name="year" className={'col'}>*/}
            {/*            { Array.from({ length: 102 }, (_, i) => `${1923 + i}`).map((year) => (*/}
            {/*                <Picker.Item key={year} value={year} className={'item'}>*/}
            {/*                    {({ selected }) => (*/}
            {/*                        <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{year}</div>*/}
            {/*                    )}*/}
            {/*                </Picker.Item>*/}
            {/*            ))}*/}
            {/*        </Picker.Column>*/}
            {/*    </Picker>*/}
            {/*</div>*/}


            {/*<motion.div*/}
            {/*    className={'picker-wrapper'}*/}
            {/*    variants={pickerAnimate}*/}
            {/*    initial={'exit'}*/}
            {/*    animate={isOpen ? 'enter' : 'exit'}*/}
            {/*>*/}

            {/*</motion.div>*/}
        </div>
    );
}

export default Page;

