// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.items {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.option {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 24px;
  border-radius: 16px;
  cursor: pointer;
}
.option span {
  font-family: "SF Pro Display", sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/check/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,yCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".items {\n  display: flex;\n  flex-direction: column;\n  gap: 14px;\n}\n\n.option {\n  display: flex;\n  justify-content: space-between;\n  background-color: #fff;\n  padding: 20px 24px;\n  border-radius: 16px;\n  cursor: pointer;\n\n  span {\n    font-family: 'SF Pro Display', sans-serif;\n    font-size: 17px;\n    font-weight: 500;\n    line-height: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
