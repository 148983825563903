import React, {Fragment, useEffect, useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import cn from "classnames";
import {useMutation} from "@tanstack/react-query";
import {AiService} from "../../services/ai.service";

function Page() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(undefined)
    const base64Image = useStore((state) => state.base64Image)
    const setBase64Image = useStore((state) => state.setBase64Image)

    useEffect(() => {
        if (base64Image) {
            processImageQuery.mutate(base64Image);
        }
    }, [base64Image]);

    const processImageQuery = useMutation({
        mutationFn: (data) => {
            return AiService.processImage({image: data});
        },
        onSuccess: (result) => {
            setData(result.data);
            setIsLoading(false);
        },
        onError: (error) => {
            console.log(error);
        }
    });

    return (
        <div className={'page chat'}>
            <div className={'content'}>
                <div className={'wrapper-progress'}>
                    <img src={'/assets/images/progress.svg'} alt={'progress'} />
                </div>

                <div className="chat-overlay">
                    <div className={'assistant'}>
                        <div className={'back'} onClick={() => {
                            setBase64Image(undefined)
                            navigate('/analysis');
                        }}><img src="/assets/images/back.svg" alt=""/></div>
                        <img className={'assistantImage'} src="/assets/images/assistant.svg" alt=""/>
                    </div>
                    <div className="wrapper-messages">
                        <div className={'message right'}>
                            <img src={base64Image} />
                        </div>
                        <div className={'message right'}>
                            <div className={'text'}>
                                🤔Analyze this meal and give nutrition tips and suggestions
                            </div>
                        </div>
                        <div className={'message left'}>
                            <div className={'answer'}>
                                <img className={'assistant-img'} src={'/assets/images/assistant.svg'} alt=""/>
                                <div className={cn('text')}>
                                    {
                                        isLoading && (
                                            <Fragment>
                                                <img src="/assets/images/analyzing.svg" alt=""/>
                                                <span>Analyzing ingredients...</span>
                                            </Fragment>
                                        )
                                    }

                                    {
                                        !isLoading && data && (
                                            <div className={'values'}>
                                                <p>Here are your meal insights!</p>
                                                <div className={'items'}>
                                                    <div className={'item'}>
                                                        <div className={'value'}>
                                                            <img src="/assets/images/carb.svg" alt=""/>
                                                            <span>{data.meal_analysis.carbohydrates}</span>
                                                        </div>
                                                        <p>Carb</p>
                                                    </div>
                                                    <div className={'item'}>
                                                        <div className={'value'}>
                                                            <img src="/assets/images/protein.svg" alt=""/>
                                                            <span>{data.meal_analysis.proteins}</span>
                                                        </div>
                                                        <p>Protein</p>
                                                    </div>
                                                    <div className={'item'}>
                                                        <div className={'value'}>
                                                            <img src="/assets/images/fat.svg" alt=""/>
                                                            <span>{data.meal_analysis.fats}</span>
                                                        </div>
                                                        <p>Fat</p>
                                                    </div>
                                                </div>
                                                <div className="notion">
                                                    🤔 {data.nutrition_tips}
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'next-button'}>
                        <button onClick={() => navigate('/personal-plan')}>Next</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Page;

