// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_container__p0Obw {
  height: 1px;
  width: 100%;
  background-color: #000;
  opacity: 0.2;
  margin: 14px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/personal-plan/layouts/personalPlan/layout/separator/style.module.scss"],"names":[],"mappings":"AAAA;EACG,WAAA;EACA,WAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;AACH","sourcesContent":[".container {\n   height: 1px;\n   width: 100%;\n   background-color: #000;\n   opacity: 0.2;\n   margin: 14px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `style_container__p0Obw`
};
export default ___CSS_LOADER_EXPORT___;
