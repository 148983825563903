import axios from 'axios';

export const getContentType = () => ({ 'Content-Type': 'application/json' });

export const instance = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_API_URL,
    headers: getContentType(),
});

export default instance;
