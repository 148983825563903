import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const atThisMoment = useStore((state) => state.atThisMoment)
    const setAtThisMoment = useStore((state) => state.setAtThisMoment)

    return (
        <div className={'page at-this-moment'}>
            <div className={'back'} onClick={() => navigate('/statement')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>At this moment, how motivated are you?</div>
                <div className={'description'}>
                    It helps us customize your program
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={atThisMoment} onChange={(value) => {
                    setAtThisMoment(value)
                    navigate('/at-this-moment')
                }}>
                    <Option value={'Im ready'}>I’m ready</Option>
                    <Option value={'Feeling hopeful'}>Feeling hopeful</Option>
                    <Option value={'Im cautious'}>I’m cautious</Option>
                    <Option value={'Taking it easy'}>Taking it easy</Option>
                </Check>
            </div>
        </div>
    );
}
export default Page;

