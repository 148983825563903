import React from 'react';
import styles from './style.module.scss'
import Graph from '../../../../components/ui/graph'

const when = {
    day: "12",
    month: "09",
    year: "2024",
}

const weight = { 
    type: "kg",
    value: 90
};

const idealWeight = { 
    type: "kg",
    value: 65
};

const GoalGraph = () => {
    return (
        <div className={styles.container}>
           <h2 className={styles.titleFirst}>You can reach yoar goal of</h2>
           <h2 className={styles.titleSecond}><span>65 kg</span> by November 2024</h2>

           <Graph
                when={when}
                weight={weight}
                idealWeight={idealWeight}
                setImageLoaded={() => {}}
                imageLoaded={true}
            />
        </div>
    );
}
export default GoalGraph;