import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const workOut = useStore((state) => state.workOut)
    const setWorkOut = useStore((state) => state.setWorkOut)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/go-for-walks')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How often do you work out?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={workOut} onChange={(value) => {
                    setWorkOut(value)
                    navigate('/sleep-per-night')
                }}>
                    <Option value={'Almost every day'}>💪   Almost every day</Option>
                    <Option value={'Several times a week'}>👍   Several times a week</Option>
                    <Option value={'Several times a month'}>🤨   Several times a month</Option>
                    <Option value={'Never'}>🙅   Never</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

