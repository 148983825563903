import React from 'react';
import './styles.scss'

const MainLayout =({children}) =>{
    return(
        <div className={'layout'}>
            {children}
        </div>
    )
}

export default MainLayout;