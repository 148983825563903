import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const mealsCount = useStore((state) => state.mealsCount)
    const setMealsCount = useStore((state) => state.setMealsCount)

    return (
        <div className={'page meals-count'}>
            <div className={'back'} onClick={() => navigate('/email')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How many meals do you eat per day?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={mealsCount} onChange={(value) => {
                    setMealsCount(value)
                    navigate('/prepare-meals')
                }}>
                    <Option value={'1'}>1 meal per day</Option>
                    <Option value={'2'}>2 meal per day</Option>
                    <Option value={'3'}>3 meal per day</Option>
                    <Option value={'4'}>4 meal per day</Option>
                    <Option value={'5'}>5+ meal per day</Option>
                </Check>

                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

