// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.name {
  padding-bottom: 180px;
}
.name .controls {
  max-width: 210px;
  width: 100%;
  margin: 0 auto;
}
.name .controls input {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 16px;
  font-family: "SF Pro Rounded", sans-serif;
  max-width: 210px;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
}
.name .controls .line {
  width: 100%;
  background: #B4B4B4;
  height: 1px;
}
.name .next-wrapper {
  position: fixed;
  bottom: 40px;
  left: 0;
  right: 0;
  top: initial;
  z-index: 1;
}
.name .picker-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  transform-origin: bottom;
}`, "",{"version":3,"sources":["webpack://./src/pages/name/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;EACA,cAAA;AAEJ;AADI;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yCAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;AAGN;AAAI;EACE,WAAA;EACA,mBAAA;EACA,WAAA;AAEN;AAEE;EACE,eAAA;EACA,YAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,UAAA;AAAJ;AAGE;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,wBAAA;AADJ","sourcesContent":[".name {\n  padding-bottom: 180px;\n  .controls {\n    max-width: 210px;\n    width: 100%;\n    margin: 0 auto;\n    input {\n      text-align: center;\n      font-size: 24px;\n      font-weight: 600;\n      line-height: 16px;\n      font-family: \"SF Pro Rounded\", sans-serif;\n      max-width: 210px;\n      width: 100%;\n      background: transparent;\n      border: none;\n      outline: none;\n    }\n\n    .line {\n      width: 100%;\n      background: #B4B4B4;\n      height: 1px;\n    }\n  }\n\n  .next-wrapper {\n    position: fixed;\n    bottom: 40px;\n    left: 0;\n    right: 0;\n    top: initial;\n    z-index: 1;\n  }\n\n  .picker-wrapper {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 2;\n    transform-origin: bottom;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
