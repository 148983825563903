import React, {useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {MultiCheck} from "../../components/ui/multi-check/multi-check";
import {MultiOption} from "../../components/ui/multi-check/option";
function Page() {
    const navigate = useNavigate();
    const [next, setNext] = useState(false);
    const dailyDrinks = useStore((state) => state.dailyDrinks)
    const setDailyDrinks = useStore((state) => state.setDailyDrinks)

    return (
        <div className={'page daily-drink'}>
            <div className={'back'} onClick={() => navigate('/prepare-meals')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>How much water do you drink daily?</div>
            </div>

            <div className={'controls'}>
                <MultiCheck defaultValue={dailyDrinks} onChange={(value) => {
                    if (value.length > 0) {
                        setNext(true)
                    } else {
                        setNext(false)
                    }
                    setDailyDrinks(value)
                }}>
                    <MultiOption value={'coffee'}>☕️   Coffee & tea</MultiOption>
                    <MultiOption value={'2'}>💧   About 2 glasses</MultiOption>
                    <MultiOption value={'6'}>💧💧  About 6 glasses</MultiOption>
                    <MultiOption value={'6+'}>💧💧💧  More than 6  glasses</MultiOption>
                </MultiCheck>
                <div className={'overlay'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    next && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/crab-dense')}/>
                    )
                }
            </div>
        </div>
    );
}
export default Page;

