import React, {useState} from 'react'
import {CheckContext} from "./context";
import './styles.scss';

export const Check = ({children, onChange, defaultValue}) => {
    const [currentValue, setCurrentValue] = useState(defaultValue);

    const handleChange = (value) => {
        setCurrentValue(value);
        onChange(value);
    }

    return (
        <div className={'items'}>
            <CheckContext.Provider value={{handleChange, currentValue}}>
                {children}
            </CheckContext.Provider>
        </div>
    )
}