import React from 'react';
import './style.scss'
import {format} from "date-fns";

const Graph = ({when, weight, idealWeight, setImageLoaded, imageLoaded}) => {
    return (
        <div className={'wrapper-graph'}>
            <div className={'graph'}>
                {
                    imageLoaded && (
                        <>
                            <span className={'weight'}>{weight.value} {weight.type}</span>
                            <span className={'goal-weight'}>{idealWeight.value} {idealWeight.type}</span>
                            <span className={'now'}>Now</span>
                            <span className={'goal-date'}>{format(new Date(when.year + '-' + when.month+'-'+ when.day +'T00:00:00'), 'MMM yyyy')}</span>
                        </>
                    )
                }
                <img onLoad={() => setImageLoaded(true)} src={'/assets/images/graph.png'} />
            </div>
            </div>
    );
}

export default Graph;