import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const workSchedule = useStore((state) => state.workSchedule)
    const setWorkSchedule = useStore((state) => state.setWorkSchedule)

    return (
        <div className={'page often'}>
            <div className={'back'} onClick={() => navigate('/vegetable-intake')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your work schedule like?</div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={workSchedule} onChange={(value) => {
                    setWorkSchedule(value)
                    navigate('/your-lifestyle')
                }}>
                    <Option value={'9 to 5'}>🌞   9 to 5</Option>
                    <Option value={'Night shifts'}>🌙    Night shifts</Option>
                    <Option value={'My hours are flexible'}>🕐   My hours are flexible</Option>
                    <Option value={'Im retired'}>👨‍🦳   I’m retired</Option>
                    <Option value={'Im not working'}>⛱   I’m not working</Option>
                </Check>
                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

