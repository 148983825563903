import React, {useEffect} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import {useStore} from "../../store/store";

function Page() {
    const navigate = useNavigate();
    const weight = useStore((state) => state.weight)
    const idealWeight = useStore((state) => state.idealWeight)
    const setIdealWeight = useStore((state) => state.setIdealWeight)

    useEffect(() => {
        if (weight.type === 'ibs') {
            setIdealWeight({...weight, type: 'ibs'})
        }
    }, []);

    return (
        <div className={'page ideal-weight'}>
            <div className={'back'} onClick={() => navigate('/loos-goal')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your ideal weight</div>
                <div className={'description'}>
                    This will help us create custom weight-loss plan for you
                </div>
            </div>
            
            <div className={'controls'}>
                <div className={'tabs'}>
                    <span className={cn({'active': idealWeight.type === 'kg'})} onClick={() => {
                        setIdealWeight({...idealWeight, type: 'kg', value: ''})
                    }}>Kg</span>
                    <span className={cn({'active': idealWeight.type === 'ibs'})} onClick={() => {
                        setIdealWeight({...idealWeight, type: 'ibs', value: ''})
                    }}>Ibs</span>
                </div>

                <input type="number" autoFocus={true} value={idealWeight.value} placeholder={'Input weight...'} onChange={(event) => setIdealWeight({...idealWeight, value: event.target.value})}/>
                <div className={'line'}></div>
            </div>

            {/*<div className={'picker-wrapper'}>*/}
            {/*    <div className={'next-wrapper next-wrapper-height'}>*/}
            {/*        {*/}
            {/*            idealWeight.value && (*/}
            {/*                <img className={'next'} src="/assets/images/next-button.png" onClick={() => navigate('/motivation')} alt=""/>*/}
            {/*            )*/}
            {/*        }*/}

            {/*        <Picker*/}
            {/*            value={{newIdealWeight: idealWeight.value}}*/}
            {/*            onChange={handlePickerChange}*/}
            {/*            wheelMode="natural"*/}
            {/*            height={150}*/}
            {/*            className={'data-picker'}*/}
            {/*        >*/}
            {/*            <Picker.Column name="newIdealWeight" className={'col'}>*/}
            {/*                { _.range(MIN_WEIGHT, MAX_WEIGHT).map((weight) => (*/}
            {/*                    <Picker.Item key={weight} value={weight} className={'item'}>*/}
            {/*                        {({ selected }) => (*/}
            {/*                            <div className={selected ? 'font-semibold text-neutral-bold' : 'text-neutral'}>{weight}</div>*/}
            {/*                        )}*/}
            {/*                    </Picker.Item>*/}
            {/*                ))}*/}
            {/*            </Picker.Column>*/}
            {/*        </Picker>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className={'next-wrapper'}>
                {
                    idealWeight.value && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/motivation')}/>
                    )
                }
            </div>
        </div>
    );
}

export default Page;

