import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const statement = useStore((state) => state.statement)
    const setStatement = useStore((state) => state.setStatement)

    return (
        <div className={'page statement'}>
            <div className={'back'} onClick={() => navigate('/change-motivation')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'} style={{padding: '0 20px'}}>
                <div className={'title'}>Do you relate to the statement below?</div>
                <div className={'message'}>
                    🤔 “I need some outside motivation. It can be easy to give up when i feel overwhelmed.”
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={statement} onChange={(value) => {
                    setStatement(value)
                    navigate('/at-this-moment')
                }}>
                    <Option value={'Yes'}>Yes</Option>
                    <Option value={'Sometimes'}>Sometimes</Option>
                    <Option value={'No'}>No</Option>
                </Check>
            </div>
        </div>
    );
}
export default Page;

