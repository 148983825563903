import React from 'react';
import './style.scss'

const Footer = () => {
    return (
      <div className='container'>
        <div className="contact-us">Contact us</div>
        <div className="address">Worszaw Pielgzimow 23 15, 3 floor, flat a5</div>
        <div className="links">
          <span className='links_top'>
            <a href="/privacy">Privacy policy</a>
            <a href="/terms">Term of Use</a>
            <a href="/billing">Billing Terms</a>
          </span>

          <span className='links_bottom'>
           <a href="/moneyback">Money-back Policy</a>
          </span>
        </div>
      </div>
    );
}

export default Footer;