import React, {useState} from 'react'
import {MultiCheckContext} from "./context";
import './styles.scss';

export const MultiCheck = ({children, onChange, defaultValue = []}) => {
    const [currentValue, setCurrentValue] = useState(defaultValue);

    const handleChange = (value) => {
        let clone = currentValue;
        const index = clone.findIndex((v) => v === value)

        if (index !== -1) {
            clone.splice(index, 1);
        } else {
            clone.push(value);
        }

        setCurrentValue([...clone]);
        onChange(clone);
    }

    return (
        <div className={'items'}>
            <MultiCheckContext.Provider value={{handleChange, currentValue}}>
                {children}
            </MultiCheckContext.Provider>
        </div>
    )
}