import React from 'react';
import './style.scss'
import {Check} from "../../components/ui/check/check";
import {Option} from "../../components/ui/check/option";
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
function Page() {
    const navigate = useNavigate();
    const motivation = useStore((state) => state.motivation)
    const setMotivation = useStore((state) => state.setMotivation)

    return (
        <div className={'page motivation'}>
            <div className={'back'} onClick={() => navigate('/ideal-weight')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>Is there some specific motivation for you?</div>
                <div className={'description'}>
                    This will help us create custom weight-loss plan for you
                </div>
            </div>

            <div className={'controls'}>
                <Check defaultValue={motivation} onChange={(value) => {
                    setMotivation(value);
                    navigate('/when')
                }}>
                    <Option value={'Vacation'}>⛱   Vacation</Option>
                    <Option value={'Wedding'}>💍   Wedding</Option>
                    <Option value={'Sport'}>🏆   Sport</Option>
                    <Option value={'Summer'}>🌞   Summer</Option>
                    <Option value={'School'}>🎒   School</Option>
                    <Option value={'Party'}>🎉   Party</Option>
                    <Option value={'Pregnant'}>🤰   Pregnant</Option>
                    <Option value={'No'}>No any special event</Option>
                </Check>

                <div className={'overlay'}></div>
            </div>
        </div>
    );
}
export default Page;

