import React, {useEffect} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import cn from "classnames";
import {useStore} from "../../store/store";

function Page() {
    const navigate = useNavigate();
    const weight = useStore((state) => state.weight)
    const height = useStore((state) => state.height)
    const setWeight = useStore((state) => state.setWeight)

    useEffect(() => {
        if (height.type === 'inch') {
            setWeight({...weight, type: 'ibs'})
        }
    }, []);

    return (
        <div className={'page weight'}>
            <div className={'back'} onClick={() => navigate('/height')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-progress'}>
                <img src={'/assets/images/progress.svg'} alt={'progress'} />
            </div>
            <div className={'wrapper-info'}>
                <div className={'title'}>What is your weight?</div>
                <div className={'description'}>
                    This will help us understand your calorie needs
                </div>
            </div>
            
            <div className={'controls'}>
                <div className={'tabs'}>
                    <span className={cn({'active': weight.type === 'kg'})} onClick={() => {
                        setWeight({...weight, type: 'kg', value: ''})
                    }}>Kg</span>
                    <span className={cn({'active': weight.type === 'ibs'})} onClick={() => {
                        setWeight({...weight, type: 'ibs', value: ''})
                    }}>Ibs</span>
                </div>

                <input type="number" autoFocus={true} value={weight.value} placeholder={'Input weight...'} onChange={(event) => setWeight({...weight, value: event.target.value})}/>
                <div className={'line'}></div>
            </div>

            <div className={'next-wrapper'}>
                {
                    weight.value && (
                        <img className={'next'} src="/assets/images/next-button.png" alt="" onClick={() => navigate('/loos-goal')}/>
                    )
                }
            </div>
        </div>
    );
}

export default Page;

