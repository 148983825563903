import React, {useEffect, useState} from 'react';
import './style.scss'
import {useNavigate} from "react-router-dom";
import {useStore} from "../../store/store";
import {defaultImage} from "./utils/variables";

function Page() {
    const navigate = useNavigate();
    const base64Image = useStore((state) => state.base64Image)
    const setBase64Image = useStore((state) => state.setBase64Image)

    useEffect(() => {
        if (base64Image) {
            navigate('/chat')
        }
    }, [base64Image])

    const handleChangeImage = (element) => {
        let file = element.files[0];
        const reader = new FileReader();
        reader.onloadend = function() {
            setBase64Image(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleDefaultImage = () => {
        setBase64Image(defaultImage);
    }

    // const handleProcess = () => {
    //     if (base64Image) {
    //         processImageQuery.mutate(base64Image);
    //     }
    // }

    // const processImageQuery = useMutation({
    //     mutationFn: (data) => {
    //         return AiService.processImage({image: data});
    //     },
    //     onSuccess: (result) => {
    //         if (result.data.food_items) {
    //             setPoints(result.data.food_items);
    //         } else if (result.data.foods) {
    //             setPoints(result.data.foods);
    //         }
    //         setLoading(false);
    //     },
    //     onError: (error) => {
    //         console.log(error);
    //         setLoading(false)
    //     }
    // });

    return (
        <div className={'page analysis'}>
            <div className={'back'} onClick={() => navigate('/at-this-moment')}><img src="/assets/images/back.svg" alt=""/></div>
            <div className={'wrapper-info'}>
                <div className={'title'}>Let's analyse one of your meal by photo</div>

                <div className={'info'}>
                    <div className="item">
                        <img src="/assets/images/sun.svg" alt=""/>
                        <p>Ensure good lighting and minimize shadows</p>
                    </div>
                    <div className="item">
                        <img src="/assets/images/meal.svg" alt=""/>
                        <p>Take a photo of the entire dish, no close-ups</p>
                    </div>
                    <div className="item">
                        <img src="/assets/images/blur.svg" alt=""/>
                        <p>Keep photos clear and don't use blurred images</p>
                    </div>
                </div>
            </div>
            {/*{loading && <img className={'loading'} src="/assets/images/loading.svg" alt="" />}*/}
            {/*{base64Image && (*/}
            {/*    <div className={'wrapper-image'}>*/}
            {/*        {*/}
            {/*            points.length > 0 && points.map((el) => {*/}
            {/*                return <div className={'point'} style={{top: el.coordinates.y * 100 + '%', left: el.coordinates.x * 100 + '%'}}>*/}
            {/*                    <span>{el.name}</span>*/}
            {/*                </div>*/}
            {/*            })*/}
            {/*        }*/}
            {/*        <img className={'image'} src={base64Image} alt="" />*/}
            {/*    </div>*/}
            {/*)}*/}
            <div className={'controls'}>
                <input type="file" id={'upload'} onChange={e => handleChangeImage(e.target)} hidden />
                <label htmlFor="upload">Upload food photo</label>
                <button onClick={() => handleDefaultImage()}>Use an example</button>
            </div>

        </div>
    );
}
export default Page;

